<template>
  <v-navigation-drawer
      :value="isAddNewAdminSidebarActive"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
      app
      @input="(val) => $emit('update:is-add-new-admin-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Admin</span>
        <v-spacer></v-spacer>
        <v-btn
            icon
            small
            @click="$emit('update:is-add-new-admin-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="onSubmit"
        >
          <v-text-field
              v-model="adminData.name"
              outlined
              dense
              :rules="[validators.required]"
              label="Admin Name"
              placeholder=""
              hide-details="auto"
              class="mb-6"
          ></v-text-field>
          <v-text-field
              v-model="adminData.email"
              outlined
              dense
              :rules="[validators.required, validators.emailValidator]"
              label="Admin Email"
              placeholder=""
              hide-details="auto"
              class="mb-6"
          ></v-text-field>
          <v-text-field
              v-model="adminData.password"
              outlined
              dense
              :rules="[validators.required]"
              label="Admin Password"
              placeholder=""
              hide-details="auto"
              class="mb-6"
          ></v-text-field>

          <v-select
              v-model="adminData.roles"
              :rules="[validators.required]"
              label="Roles"
              :items="roleOptions"
              item-text="name"
              item-value="name"
              outlined
              dense
              hide-details="auto"
              class="mb-6"
              multiple
          >
          </v-select>

          <v-btn
              color="primary"
              class="me-3"
              type="submit"
          >
            Add
          </v-btn>
          <v-btn
              color="secondary"
              outlined
              type="reset"
              @click="resetAdminData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import {mdiClose} from "@mdi/js"
import countries from "@/@fake-db/data/other/countries"
import store from "@/store"
import {ref} from "@vue/composition-api"
import {required, emailValidator} from "@core/utils/validation"

export default {
  model: {
    prop: "isAddNewAdminSidebarActive",
    event: "update:is-add-new-admin-sidebar-active",
  },
  props: {
    isAddNewAdminSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, {emit}) {
    const blankAdminData = {
      name: "",
      email: "",
      password: "",
      roles: []
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const adminData = ref(JSON.parse(JSON.stringify(blankAdminData)))
    const resetAdminData = () => {
      adminData.value = JSON.parse(JSON.stringify(blankAdminData))
      resetForm()
      emit("update:is-add-new-admin-sidebar-active", false)
    }

    const onSubmit = () => {
      if (valid.value) {
        store.dispatch("am-admin/addAdmin", adminData.value).then(() => {
          emit("refetch-data")
          emit("update:is-add-new-admin-sidebar-active", false)
        })

        resetAdminData()
      } else {
        validate()
      }
    }

    return {
      resetAdminData,
      form,
      onSubmit,
      adminData,
      valid,
      validate,

      // validation
      validators: {required, emailValidator},
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
